import React from 'react'
import './Footer.css'

export default () => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <span>
        Capital Area Cardiovascular Associates © Copyright {new Date().getFullYear()} All rights reserved.<br/>
         875 Popular Church Rd. Suite 300 Camp Hill, PA - 223.225.1120
        </span>
      </div>
    </footer>
  </div>
)

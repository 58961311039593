import React from 'react'
import './Logo.css'

export default () => (
  <div
    className="Logo"
    style={{
      backgroundImage: `url(/images/CARE_logo.png)`,
      width: '76px',
      height: '46px'
    }}
  />
)

import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import Logo from './Logo'

import './Nav.css'
const aboutContent = [
  {slug: "http://localhost:8000/about/#meet-nadar", title: "Meet. Dr. Nadar"},
  {slug: "http://localhost:8000/about/#meet-lewin", title: "Meet Dr. Lewin"},
  {slug: "http://localhost:8000/about/#why", title: "Why Choose Capital Area Cardiovascular Associates?"},
  {slug: "http://localhost:8000/about/#capital-area-research", title: "Capital Area Research"},
]
export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      ),
      NavLinkBlank = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          target="_blank"
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
         <div className="Nav--Container container" style={{display: "flex",
    justifyContent: "center"}}>
           <span><a href="tel:223-225-1120">223-225-1120</a></span>
           <div className="Nav--Links">
           <NavLink to="/contact/">Request An Appointment</NavLink>
           <NavLink to="/contact/">Directions</NavLink>
          </div>
         </div>
        <div className="Nav--Container container">
          <Link to="/" onClick={this.handleLinkClick}>
            <Logo />
          </Link>
          <div className="Nav--Links">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/about/">About</NavLink> */}
            {/* <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'about' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('about') 

                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('about')}
              >
                About
                <div className="Nav--GroupLinks">
                  <NavLink to="/about/" className="Nav--GroupLink">
                    About
                  </NavLink>
                  {aboutContent.map((link, index) => (
                    <NavLink
                      to={link.slug}
                      key={'posts-subnav-link-' + index}
                      className="Nav--GroupLink"
                    >
                      {link.title}
                    </NavLink>
                  ))}
                </div>
              </span>
            </div> */}
            <NavLink to="/about/">About</NavLink>
            <NavLink to="/services/">Services</NavLink>
            {/* <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('posts')}
              >
                Services
                <div className="Nav--GroupLinks">
                  <NavLink to="/services/" className="Nav--GroupLink">
                    All Services
                  </NavLink>
                  {subNav.posts.map((link, index) => (
                    <NavLink
                      to={link.slug.replace('post-categories','posts')}
                      key={'posts-subnav-link-' + index}
                      className="Nav--GroupLink"
                    >
                      {link.title}
                    </NavLink>
                  ))}
                </div>
              </span>
            </div> */}
            <NavLink to="/fees/">Insurance and Fee Schedule</NavLink>
            {/* <NavLink to="/glossary/">Patient Resources</NavLink> */}
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'pf' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('pf') 

                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('pf')}
              >
               Patient Resources
                <div className="Nav--GroupLinks">
                  {/* <NavLink to="/studies/" className="Nav--GroupLink">
                    All Studies
                  </NavLink> */}
                      <NavLinkBlank
                      to={"https://26547.portal.athenahealth.com"}
                      key={'posts-subnav-link-8'}
                      className="Nav--GroupLink"
                    >
                     Patient Portal
                    </NavLinkBlank>
                    <NavLink
                      to="/forms/"
                      key={'posts-subnav-link-9'}
                      className="Nav--GroupLink"
                    >
                     Patient Forms
                    </NavLink>
                </div>
              </span>
            </div>
            <NavLink to="/news/">News</NavLink>
            <NavLink to="/contact/">Contact Us</NavLink>

          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
